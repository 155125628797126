.Header {
	background-color : #ffffff;
	position: sticky; top: 0;
	width: 100%;
	z-index: 1000;
}

.LogoWrapper {
	padding : 16px 16px 15px 16px;
}

.SignInWrapper {
	padding : 16px 16px 15px 16px;
	text-align: right;
}

.MenuIcon {
	float: right;
}

.LoginButtonWrapper {
	padding : 10px 10px;
}

.ButtonPrimary {
	min-width: 98px;
}
