.Wrapper {
	max-width: 116px;
	text-align: center;
	cursor: pointer;
}

.Text {
	font-size: 9px;
	color: #37694A;
	font-weight: bold;
}
