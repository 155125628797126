.Header {
	background-color : #ffffff;
	position: sticky; top: 0;
	width: 100%;
	z-index: 1000;
}

.LogoWrapper {
	padding : 16px 16px 15px 16px;
}

.MenuWrapper {
	text-align: right;
	position: relative;
}

.Menu {
	right: 0;
	position: absolute;
	background-color: white;
	z-index: 100;
	padding: 20px 20px 10px;
	box-shadow: 0 3px 7px 0 #d2cfcf;
	width: fit-content;
}

.MenuItem {
	margin-bottom: 10px;
	padding-left: 3px;
	padding-right: 3px;
	font-size: 21px;
	font-weight: 300;

}

.MenuItem:hover {
	background-color: #ececec;
}

.MenuLink {
	color: #606060;
}


.MenuLink:hover {
	color: #EA7E3D;
}

.NotificationIcon {
	margin-top: 24px;
	margin-right: 25px;
	cursor: pointer;
}

.MenuIcon {
	margin-top: 24px;
	margin-right: 10px;
	cursor: pointer;
}

.LogoInnerWrapper {
	margin: 0;
}

.LoginButtonWrapper {
	padding : 10px 10px;
}

.ButtonPrimary {
	min-width: 98px;
}
